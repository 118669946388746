body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body * {
    font-family: Nunito !important;
}

h1 {
    color: #e91e63;
}

.mainTitle {
    margin-top: auto;
}

.bottomBar {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    margin-bottom: 10px;
}

.attendenceList {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 0.4em;
}

.mainFab {
    position: absolute !important;
    right: 20px;
    bottom: 20px;
}